<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Button from '@/Components/Button/Button.vue';
import Title from '@/Components/Title/Title.vue';

const route = inject('route');
const props = defineProps({
    position: Object,
});

const form = useForm({ ...props.position });

const sendForm = () => {
    form.put(route('staff.positions.update', { position: props.position.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => {
            form.errors = mapErrors(errors, (error) => [error]);
        },
    });
};
</script>

<template>
    <FormKit type="form" @submit="sendForm" :actions="false">
        <Title :title="$t('Edit {model}', { model: $t('parent position') })" />
        <div class="grid grid-cols-2 mt-4 gap-x-8 gap-y-2">
            <FormKit
                type="text"
                name="name.en"
                :label="$t('Name (EN)')"
                v-model="form.name.en"
                :errors="form.errors.name?.en"
                :value="form.name.en"
                validate="required"
            />
            <FormKit
                type="text"
                name="name.nl"
                :label="$t('Name (NL)')"
                v-model="form.name.nl"
                :errors="form.errors.name?.nl"
                :value="form.name.nl"
                validate="required"
            />
        </div>
        <div class="flex flex-row items-center justify-end">
            <Button :processing="form.processing" :text="$t('Save')" />
        </div>
    </FormKit>
</template>
